import { useEffect, useState, useCallback } from "react";
import { View, StyleSheet, Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import AsyncStorage from "@react-native-async-storage/async-storage";
import BackButton from "../components/navigation/BackButton/BackButton";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import { useNavigation, useRoute } from "@react-navigation/native";
import { MotiView } from "moti";
import { searchWeb } from "../services";
import FileCard from "../components/lms/FileCard/FileCard";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { formatDate } from "../utils/date";
import CustomHtml from "../components/common/general/CustomHtml/CustomHtml";
import { useChatBar } from "../contexts/ChatBarContext";
import SourcesList from "../components/lms/SourcesList/SourcesList";
import ImageCardList from "../components/lms/ImageCardList/ImageCardList";
import VideoCardList from "../components/lms/VideoCardList/VideoCardList";
import Divider from "../components/common/layout/Divider/Divider";
import Loader from "../components/common/status/Loader/Loader";
import { useAppConfig } from "../AppConfigProvider";
import useWebSocket from "../hooks/useWebSocket";
import Sentry from "../utils/sentry";
import { useGetChatsQuery, useGetAiTutorQuery } from "../graphql/generated/graphql";
import { shadowStyles } from "../styles/shadow";
import CustomProgressBar from "../components/common/general/CustomProgressBar/CustomProgressBar";
import Skeleton from "react-loading-skeleton";
import { useAppState } from "../contexts/AppStateContext";
import { useFocusEffect } from "@react-navigation/native";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import ActivityInstructionsModal from "../components/lms/ActivityInstructionsModal/ActivityInstructionsModal";

function CustomResourceStudentView() {
  const [isLoadingSidebarData, setIsLoadingSidebarData] = useState(true);
  const navigation = useNavigation();
  const route = useRoute();
  const params = route.params;
  const resourceType = params.resource_type;
  const resourceId = params.resource_id;
  const courseId = params.course_id;
  const integrationId = params.integration_id;
  const { setShowChat, mapRouteToContext, setConversationType, setSchoolMessage, setMetaIsLoading } = useChatBar();
  const { isMedium } = useResponsiveScreen();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const appConfig = useAppConfig();
  const isAxioOffering = appConfig.isAxioOffering;
  const isSuggestedInsights = appConfig.isSuggestedInsights;
  const { sendMessage } = useWebSocket();
  const [videoCardData, setVideoCardData] = useState([]);
  const [sources, setSources] = useState([]);
  const [imageCardData, setImageCardData] = useState([]);
  const { data: chatsData } = useGetChatsQuery();
  const [chatExists, setChatExists] = useState(false);
  const { dispatch } = useAppState();

  const renderSkeletonLoader = () => (
    <View style={styles.skeletonContainer}>
      <View style={styles.detailsContainer}>
        {/* Header Section */}
        <View style={styles.headerContainer}>
          <Skeleton width={40} height={40} circle={true} />
          <CustomSpacing type="horizontal" size="m" />
          <Skeleton width="60%" height={24} />
        </View>

        <CustomSpacing type="vertical" size="m" />

        <CustomSpacing type="vertical" size="l" />

        {/* Button Section */}
        <Skeleton width="30%" height={40} borderRadius={8} />

        <CustomSpacing type="vertical" size="l" />

        {/* Suggested Insights Section */}
        <Skeleton width="100%" height={200} borderRadius={12} />
      </View>

      {/* Sidebar Section */}
      <View style={styles.sidebarContainer}>
        <Skeleton width="80%" height={24} />
        <CustomSpacing type="vertical" size="s" />
        <Skeleton width="100%" height={150} borderRadius={8} />
      </View>
    </View>
  );

  const { data: aiTutorData, loading } = useGetAiTutorQuery({
    variables: { id: resourceId },
    skip: !resourceId,
  });

  let totalObjectives = 0;
  let completedObjectives = 0;
  let resourceDurationMinutes = 0;
  let isComplete = false;

  if (aiTutorData && aiTutorData.getAITutor) {
    if (
      Array.isArray(aiTutorData.getAITutor.data?.[0].objectives) &&
      aiTutorData.getAITutor.data?.[0].objectives.length > 0
    ) {
      // Only use the objectives array if it exists and has items
      totalObjectives = aiTutorData.getAITutor.data?.[0].objectives.length;
      completedObjectives = aiTutorData.getAITutor.data?.[0].objectives.filter(
        (objective) => objective.status === "completed"
      ).length;
    } else {
      // Handle both null/undefined AND empty array cases
      totalObjectives = 4;
      completedObjectives = 0;
    }

    isComplete = totalObjectives > 0 && completedObjectives === totalObjectives;

    // Calculate remaining time based on incomplete objectives
    const incompleteObjectives = totalObjectives - completedObjectives;
    resourceDurationMinutes = incompleteObjectives * 10;
  }

  const progressPercentage = totalObjectives > 0 ? (completedObjectives / totalObjectives) * 100 : 0;

  useFocusEffect(
    useCallback(() => {
      // Reset media state when the component is focused
      dispatch({
        type: "SET_MEDIA",
        payload: {
          video: null,
          wiki: null,
        },
      });

      // Optional cleanup if needed
      return () => {
        dispatch({
          type: "SET_MEDIA",
          payload: {
            video: null,
            wiki: null,
          },
        });
      };
    }, [dispatch])
  );

  useEffect(() => {
    setConversationType("SCHOOL");
    setSchoolMessage({
      contentType: resourceType,
      contentId: resourceId,
      classId: courseId,
      integrationId,
    });
  }, [resourceId, courseId, integrationId, resourceType]);

  const checkChatExists = useCallback(() => {
    if (chatsData?.getChats) {
      const existingChat = chatsData.getChats.find(
        (chat) => chat.objectId === resourceId && chat.context === "ai_tutor"
      );
      setChatExists(!!existingChat);
    }
  }, [chatsData, resourceId]);

  useEffect(() => {
    checkChatExists();
  }, [checkChatExists]);

  //we do not have this data on BE
  const pointsPossible = aiTutorData?.getAITutor?.data?.[0].points_possible;
  const submission = aiTutorData?.getAITutor?.data?.[0].grade_points;
  const percentage = (submission / pointsPossible) * 100;
  const grade = submission ? `${percentage.toFixed(2)}%` : "Ungraded";

  useEffect(() => {
    if (aiTutorData?.getAITutor.data?.[0].title) {
      const fetchData = async () => {
        try {
          setIsLoadingSidebarData(true);
          const token = await AsyncStorage.getItem("token");
          const data = await searchWeb(aiTutorData.getAITutor.data?.[0].id, token);

          const videos = data.video.map((item) => ({
            imageUrl: item.metadata.videoThumbnail,
            title: item.metadata.title,
            url: item.metadata.source,
          }));

          // Update to only use the first image from the data
          const images = data.images.flatMap((item) =>
            item.metadata.images.slice(0, 1).map((image) => ({
              imageUrl: image,
              title: item.metadata.title,
              source: item.metadata.source,
            }))
          );

          const wikipedia = data.Wikipedia.map((item) => ({
            content: item.pageContent,
            title: item.metadata.title,
            source: item.metadata.source,
          }));

          setVideoCardData(videos);
          setImageCardData(images);
          setSources(wikipedia);
          setIsLoadingSidebarData(false);
        } catch (error) {
          Sentry.captureException(error);
          setIsLoadingSidebarData(false);
        }
      };

      fetchData();
    }
  }, [aiTutorData]);

  const navigateToLMS = () => {
    Linking.openURL(resource.url);
  };

  const isAnnouncement = resourceType === "announcement";

  const handleBackPress = () => {
    navigation.navigate("Custom Course Student", {
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const handleGetHelpFromAxio = async () => {
    const token = await AsyncStorage.getItem("token");
    setIsModalVisible(true); // Show modal first

    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: "SCHOOL",
        context: mapRouteToContext(route.name),
        params: route.params,
        school: {
          contentType: resourceType,
          contentId: resourceId,
          classId: courseId,
          integrationId,
        },
      },
      content: {
        message: chatExists ? "Let’s continue where we left off" : "Please begin by introducing me to this topic",
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
    setShowChat(true);
  };

  const openChatHistory = async () => {
    setShowChat(true);
  };

  const labelText =
    resourceType === "announcement"
      ? "Announcement"
      : resourceType === "AITutor"
        ? isAxioOffering
          ? "Activity"
          : "AiTutor"
        : resourceType === "page"
          ? "Material"
          : "Resource";
  const titleText = isAnnouncement ? "Description" : "Instructions";

  const renderSidebar = () => {
    const hasData = sources.length > 0 || imageCardData.length > 0 || videoCardData.length > 0;

    if (!hasData) {
      return null;
    }

    return (
      <MotiView
        from={{ opacity: 0, translateY: 20, scale: 1 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        delay={10}
        style={styles.sidebarContainer}
        transition={{ type: "timing", duration: 600 }}>
        {sources.length > 0 && (
          <>
            <CustomText text="Sources" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <SourcesList sources={sources} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {imageCardData.length > 0 && (
          <>
            <CustomText text="Images" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <ImageCardList imageCardData={imageCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {videoCardData.length > 0 && (
          <>
            <CustomText text="Videos" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <VideoCardList videoCardData={videoCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}
      </MotiView>
    );
  };

  return (
    <NoChatBarLayout>
      <ActivityInstructionsModal
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
      {loading ? (
        renderSkeletonLoader()
      ) : (
        <View style={isMedium ? styles.mainContainer : styles.mainContainerMobile}>
          {loading ? (
            <View style={styles.loaderContainer}></View>
          ) : (
            <>
              <View style={isMedium ? styles.rowContainer : styles.columnContainer}>
                <View style={isMedium ? styles.contentContainer : styles.fullWidthContentContainer}>
                  {isMedium ? (
                    <>
                      <MotiView
                        from={{
                          opacity: 0,
                          translateY: 20,
                          scale: 1,
                        }}
                        animate={{
                          opacity: 1,
                          translateY: 0,
                          scale: 1,
                        }}
                        delay={10}
                        transition={{ type: "timing", duration: 600 }}>
                        <View style={styles.headerContainer}>
                          <BackButton onPress={handleBackPress} />
                          <CustomText
                            useTranslationText={false}
                            text={aiTutorData?.getAITutor?.data?.[0].title}
                            size="xs"
                            textType="display"
                          />
                        </View>

                        <Divider type="vertical" />
                      </MotiView>
                    </>
                  ) : (
                    <>
                      <MotiView
                        from={{
                          opacity: 0,
                          translateY: 20,
                          scale: 1,
                        }}
                        animate={{
                          opacity: 1,
                          translateY: 0,
                          scale: 1,
                        }}
                        delay={10}
                        transition={{ type: "timing", duration: 600 }}>
                        <View style={styles.headerContainer}>
                          <BackButton onPress={handleBackPress} />
                          <CustomText
                            useTranslationText={false}
                            text={aiTutorData?.getAITutor?.data?.[0].title}
                            size="m"
                            weight="bold"
                          />
                        </View>
                        <CustomSpacing type="vertical" size="s" />

                        <View style={styles.mobileRow}>
                          <RoundedLabel text={labelText} styleType="highlight" />
                          <CustomSpacing type="horizontal" size="l" />

                          <View style={styles.detailRow}>
                            <CustomText text="Due " weight="bold" size="xs" />

                            <CustomText
                              useTranslationText={false}
                              text={formatDate(aiTutorData?.getAITutor?.data?.[0].created, !isAnnouncement)}
                              size="xs"
                            />
                          </View>

                          <CustomSpacing type="horizontal" size="l" />
                          {/* Grade Display */}
                          {resourceType === "AITutor" && (
                            <>
                              <View style={styles.detailRow}>
                                <CustomText text="Grade " weight="bold" size="xs" />

                                <CustomText text={grade} size="xs" />
                              </View>
                              <CustomSpacing type="horizontal" size="l" />
                            </>
                          )}

                          {!isAnnouncement && (
                            <>
                              <CustomButton
                                text="Submit"
                                styleType="primaryLight"
                                bold={true}
                                size="xs"
                                onPress={navigateToLMS}
                                style={[styles.buttonBorder, shadowStyles.primary, { marginTop: 10 }]}
                                aria-label="Submit aiTutor"
                              />
                              <CustomSpacing type="horizontal" size="l" />
                              <CustomButton
                                text={chatExists ? "Continue Help From Axio" : "Get Help From Axio"}
                                styleType="primaryLight"
                                bold={true}
                                size="xs"
                                onPress={handleGetHelpFromAxio}
                                style={[styles.buttonBorder, shadowStyles.primary, { marginTop: 10 }]}
                                aria-label="Get help from Axio"
                              />
                            </>
                          )}
                          <CustomSpacing type="horizontal" size="l" />
                        </View>
                      </MotiView>
                    </>
                  )}
                  <CustomSpacing type="vertical" size="l" />

                  <MotiView
                    from={{
                      opacity: 0,
                      translateY: 20,
                      scale: 1,
                    }}
                    animate={{
                      opacity: 1,
                      translateY: 0,
                      scale: 1,
                    }}
                    delay={600}
                    transition={{ type: "timing", duration: 600 }}
                    style={{ paddingHorizontal: 30, paddingVertical: 10 }}>
                    <View style={styles.progressColumn}>
                      <CustomText
                        style={styles.timeLeftText}
                        useTranslationText={false}
                        text={`${resourceDurationMinutes} minutes left`}
                      />
                      <View style={styles.progressBar}>
                        <CustomProgressBar
                          ariaLabel="Course progress bar"
                          progress={progressPercentage}
                          color={"#3E68FE"}
                        />
                      </View>
                    </View>
                    <CustomText
                      useTranslationText={false}
                      text={titleText}
                      size="m"
                      weight="bold"
                      role="header"
                      aria-level="2"
                    />
                    <CustomSpacing type="vertical" size="s" />
                    <View>
                      <CustomHtml
                        html={`
                <p style="font-family: Inter; line-height: 1.5; color: #344054; margin-top: 12px;">
                  You will engage in a guided discussion where you can ask questions, express your thoughts, and receive detailed explanations. This conversational approach allows you to learn at your own pace while ensuring thorough understanding of each topic.
                </p>
                <p style="font-family: Inter; line-height: 1.5; color: #344054; margin-top: 12px;">
                  Your progress will be automatically tracked as you demonstrate understanding, with topics checked off until all learning objectives are complete.
                </p>
              `}
                      />
                    </View>

                    <View style={styles.buttonContainer}>
                      {isComplete ? (
                        <View style={styles.completedButtonContainer}>
                          <CustomButton
                            text="Completed"
                            accessibilityHint="This resource has been completed"
                            size="l"
                            bold={true}
                            styleType="lightGreen"
                            style={[styles.getStartedButton, { marginRight: 10 }]}
                            disabled={true}
                          />
                          <CustomButton
                            text="View Chat"
                            accessibilityHint="Press to view chat history"
                            size="l"
                            bold={true}
                            styleType="primary"
                            style={[styles.getStartedButton, { marginRight: 20 }]}
                            onPress={openChatHistory}
                          />
                        </View>
                      ) : (
                        <CustomButton
                          text={chatExists ? "Continue" : "Get Started"}
                          accessibilityHint="Press to get started"
                          size="l"
                          bold={true}
                          styleType="primary"
                          style={[styles.getStartedButton, { marginRight: 20 }]}
                          onPress={chatExists ? openChatHistory : handleGetHelpFromAxio}
                        />
                      )}
                    </View>
                  </MotiView>

                  {aiTutorData?.getAITutor?.data?.[0].attachments?.length > 0 && (
                    <>
                      <CustomSpacing type="vertical" size="s" />
                      <CustomText text={"Attachments"} size="m" weight="bold" role="header" aria-level="2" />

                      <CustomSpacing type="vertical" size="s" />
                      <View style={styles.scrollViewWrapper}>
                        <ScrollView
                          horizontal={true}
                          showsHorizontalScrollIndicator={false}
                          style={styles.fileCardsScrollView}
                          contentContainerStyle={{
                            gap: 15,
                          }}>
                          {aiTutorData?.getAITutor?.data?.[0].attachments.map((attachment) => (
                            <FileCard key={attachment.url} title={attachment?.title} url={attachment?.url} />
                          ))}
                        </ScrollView>
                      </View>
                      <CustomSpacing type="vertical" size="l" />
                    </>
                  )}
                </View>

                {isSuggestedInsights && (
                  <>
                    {isMedium ? (
                      <>
                        <View style={[{ width: 300 }, isLoadingSidebarData ? { alignItems: "center" } : {}]}>
                          <CustomText
                            style={{ margin: 12 }}
                            textType="display"
                            text="Suggested Insights"
                            size="xs"
                            bold={false}
                            role="heading"
                            aria-level="1"
                          />
                          {isLoadingSidebarData ? (
                            <View styles={styles.insightsCenterView}>
                              <Loader />
                            </View>
                          ) : (
                            <>{renderSidebar()}</>
                          )}
                        </View>
                      </>
                    ) : (
                      <>
                        <Divider size="l" />
                        <CustomText
                          textType="display"
                          text="Suggested Insights"
                          size="xs"
                          bold={true}
                          weight="bold"
                          role="heading"
                          aria-level="1"
                        />
                        <View style={isLoadingSidebarData ? { alignItems: "center" } : {}}>
                          {isLoadingSidebarData ? (
                            <View style={styles.fullWidthContentContainer}>
                              <Loader />
                            </View>
                          ) : (
                            <>{renderSidebar()}</>
                          )}
                        </View>
                      </>
                    )}
                  </>
                )}
              </View>
            </>
          )}
        </View>
      )}
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  skeletonContainer: {
    flex: 1,
    flexDirection: "row", // Reflects the row layout in the loaded UI
    justifyContent: "space-between",
  },
  // Details container for central content
  detailsContainer: {
    flex: 3, // Takes 3/4 of the width for the main content
    paddingRight: 16,
  },
  mainContainerMobile: {
    height: 20,
  },
  mainContainer: {
    // flex: 1,
    height: 20,
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
  },
  columnContainer: {
    flexDirection: "column",
    flex: 1,
  },
  fullWidthContentContainer: {
    padding: 16,
    // height: 20,
  },
  contentContainer: {
    flex: 3, // Takes 3/4 of the width
    padding: 16,
  },
  headerText: {
    marginBottom: 6,
  },
  progressBar: {
    width: 150,
    maxWidth: 150,
  },
  sidebarContainer: {
    flex: 1, // Takes 1/4 of the width
    justifyContent: "flex-start",
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  buttonContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  completedButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  fileCardsScrollView: {
    flex: 1,
  },
  scrollViewWrapper: {
    height: 85,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  buttonBorder: {
    borderWidth: 1,
    borderColor: "rgba(5, 6, 15, 0.1)",
  },
  insightsCenterView: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  getStartedButton: {
    justifyContent: "center",
  },
  progressColumn: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  timeLeftText: {
    fontSize: 12,
    color: "#666",
    marginBottom: 5,
  },
});

export default CustomResourceStudentView;
