import { Svg, Path } from "react-native-svg";

const CheckedCircle2 = ({ fill, ...props }) => (
  <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15ZM21.3006 10.3661C21.7887 10.8543 21.7887 11.6457 21.3006 12.1339L12.9672 20.4672C12.5401 20.8944 11.8807 20.9477 11.3955 20.6274C11.3262 20.5816 11.2605 20.5282 11.1995 20.4672L7.86612 17.1339C7.37796 16.6457 7.37796 15.8543 7.86612 15.3661C8.35427 14.878 9.14573 14.878 9.63388 15.3661L12.0833 17.8156L19.5328 10.3661C20.0209 9.87796 20.8124 9.87796 21.3006 10.3661Z"
      fill={fill}
    />
  </Svg>
);

export default CheckedCircle2;
