import { useState, useEffect, useMemo } from "react";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import { useGetAllAiTutorsByCourseQuery, useGetAllCoursesBySchoolQuery } from "../../../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import ClassCardProgressList from "../../../components/lms/ClassCardProgressList/ClassCardProgressList";
import { useAppConfig } from "../../../AppConfigProvider";
import { useAuth } from "../../../hooks/useAuth";

function ClassCardProgressListContainer({ integrationId, activeTab }) {
  const [currentCourses, setCurrentCourses] = useState([]);
  const navigation = useNavigation();
  const appConfig = useAppConfig();
  const hasCourseEnrollment = appConfig.hasCourseEnrollment;
  const { rolePermissions } = useAuth();

  const { data: courses, loading: coursesLoading } = useGetAllCoursesBySchoolQuery({
    variables: {
      schoolId: integrationId,
      filterByUser: hasCourseEnrollment,
    },
    skip: !integrationId,
  });

  useEffect(() => {
    if (courses) {
      setCurrentCourses(courses.getAllCoursesBySchool.data);
    }
  }, [courses]);

  const { data: aiTutorsData, refetch: aiTutorsRefetch } = useGetAllAiTutorsByCourseQuery({
    variables: {
      courseIds: currentCourses?.map((course) => course.id),
    },
    skip: !currentCourses || currentCourses.length === 0,
  });

  const filteredCourses = useMemo(() => {
    if (activeTab === "all" || activeTab === "core") {
      return currentCourses;
    } else {
      return [];
      // return courses.filter((course) => course.type.toLowerCase() === activeTab);
    }
  }, [currentCourses, activeTab]);

  function calculateProgress(aiTutors) {
    if (!aiTutors || aiTutors.length === 0) return 0;

    let totalObjectives = 0;
    let completedObjectives = 0;

    aiTutors.forEach((aiTutor) => {
      const objectives = aiTutor.objectives;
      if (objectives && objectives.length > 0) {
        totalObjectives += objectives.length;
        completedObjectives += objectives.filter((objective) => objective.status === "completed").length;
      } else {
        totalObjectives += 4;
      }
    });

    return totalObjectives > 0 ? Math.round((completedObjectives / totalObjectives) * 100) : 0;
  }

  function formatCoursesData(courses, aiTutors) {
    return (courses ?? []).map((course) => {
      const filteredAiTutors = aiTutors?.filter((aiTutor) => aiTutor.courseId === course.id);
      const progress = calculateProgress(filteredAiTutors);
      return {
        ...course,
        progress,
      };
    });
  }

  useEffect(() => {
    if (!!courses && courses.getAllCoursesBySchool.data?.length) {
      aiTutorsRefetch();
    }
  }, [courses, aiTutorsRefetch]);

  const handlePress = (id) => {
    const course = currentCourses.filter((course) => course.id === id)[0];
    navigation.replace("Custom Course Student", {
      course_id: course.id,
      integration_id: integrationId,
    });
  };

  const handleOnHoverIn = (id) => {
    setCurrentCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: true,
          };
        }

        return course;
      })
    );
  };

  const handleOnHoverOut = (id) => {
    setCurrentCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: false,
          };
        }

        return course;
      })
    );
  };

  const handleEditPress = (id) => {
    navigation.navigate("Custom Coursework Modules", {
      courseId: id,
    });
  };

  const handleAnalyticsPress = () => {
    navigation.navigate("Analytics");
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {coursesLoading ? (
        <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 20, marginBottom: 20, justifyContent: "center" }}>
          {[...Array(3)].map((_, index) => (
            <Skeleton width={400} height={210} margin={10} borderRadius={30} key={index} />
          ))}
        </View>
      ) : (
        <>
          {aiTutorsData && filteredCourses && (
            <ClassCardProgressList
              courses={formatCoursesData(filteredCourses, aiTutorsData?.getAllAITutorsByCourse.data)}
              onPress={handlePress}
              onHoverIn={handleOnHoverIn}
              onHoverOut={handleOnHoverOut}
              isLoading={coursesLoading}
              hasEdit={rolePermissions.includes("editCourse")}
              hasAnalytics={rolePermissions.includes("viewSchoolAnalytics")}
              onEditPress={handleEditPress}
              onAnalyticsPress={handleAnalyticsPress}
              isNull={filteredCourses.length === 0}
            />
          )}
        </>
      )}
    </MotiView>
  );
}

export default ClassCardProgressListContainer;
